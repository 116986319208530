import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

// My Components
import PlayersTable from "./components/players/players.table";
import PlayerCards from "./components/players/players.cards";
import TechnicalTable from "./components/technicalmembers/technical.table";
import TechnicalCards from "./components/technicalmembers/technical.cards";
import FButton from "../../../components/button/FButton";
import ErrorAlert from "../../../components/pops/ErrorAlert";
import constants from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 40,
    paddingLeft: 60,
    paddingRight: 60,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    maxWidth: 2015,
    margin: '0 auto',
  },
  divider: {
    background: theme.palette.divider,
    marginTop: 50,
    marginBottom: 50,
  },
  saveChanges: {
    alignSelf: "flex-end",
    marginBottom: 44,
    maxWidth: 350,
  },
  playerCards: {
    width: "100%",
    paddingTop: 78,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 30,
    }
  },
  technicalCards: {
    paddingTop: 114,
    paddingLeft: 30,
    justifyContent: "flex-end",
    [theme.breakpoints.up('lg')]: {
      paddingTop: 119,
      paddingLeft: 40,

    }
  },
  players: {
    justifyContent: 'space-between',
  },
  technical: {
    justifyContent: 'space-between',
  }
}));

export default function Scalation({ team, mainContext }) {
  const classes = useStyles();

  const hasChanges = () => {
    return mainContext.updatedPlayers.length === 0;
  };

  return (
    <div className={classes.root}>
      {/* Escalação de Jogadores */}
      <Grid container id="teste">
        <Grid
          item
          sm={12}
          md={12}
          lg={8}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4">Jogadores Inscritos no time</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3">{team.name}</Typography>
            <Typography variant="body1">{team.colors}</Typography>
          </Grid>
          <Grid item>
            <PlayersTable team={team} mainContext={mainContext}></PlayersTable>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          lg={4}
          spacing={2}
          className={classes.playerCards}
        >
          <Grid container item className={classes.saveChanges}>
            <FButton
              thin
              fullWidth
              type="secondary"
              onClick={() => {
                mainContext.updateStep(mainContext.step);
              }}
              disabled={hasChanges()}
            >
              Salvar alterações
            </FButton>
          </Grid>
          <Grid item >
            <PlayerCards team={team} mainContext={mainContext}></PlayerCards>
          </Grid>
        </Grid>
      </Grid>
      {/* Escalação de Equipe Téncica */}
      <Divider className={classes.divider} />
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        alignContent="flex-start"
        wrap="nowrap"
        className={classes.technical}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          justify="space-between"
          spacing={1}
        >
          <Grid item>
            <Typography variant="h4">
              Membros da equipe técnica do time
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3">{team.name}</Typography>
            <Typography variant="body1">{team.colors}</Typography>
          </Grid>
          <Grid item>
            <TechnicalTable team={team}></TechnicalTable>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          spacing={2}
          direction="column"
          className={classes.technicalCards}>
          <Grid item>
            <TechnicalCards team={team}></TechnicalCards>
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      {mainContext.goalKeeperError &&
        <ErrorAlert
          open={mainContext.goalKeeperError}
          onClose={() => mainContext.setGoalKeeperError(false)}
          message={constants.GOALKEEPERERROR}
        ></ErrorAlert>
      }

      {mainContext.playerNumberError &&
        <ErrorAlert
          open={mainContext.playerNumberError}
          onClose={() => mainContext.setPlayerNumberError(false)}
          message={constants.PLAYERNUMBERERROR}
        ></ErrorAlert>
      }

      {mainContext.hasNoNumberError &&
        <ErrorAlert
          open={mainContext.hasNoNumberError}
          onClose={() => mainContext.setHasNoNumberError(false)}
          message={constants.HASNONUMBERERROR}
        ></ErrorAlert>
      }

      {mainContext.hasNoCaptainError &&
        <ErrorAlert
          open={mainContext.hasNoCaptainError}
          onClose={() => mainContext.setHasNoCaptainError(false)}
          message={constants.HASNOCAPTAINERROR}
        ></ErrorAlert>
      }

      {mainContext.hasNoGoalKeeper &&
        <ErrorAlert
          open={mainContext.hasNoGoalKeeper}
          onClose={() => mainContext.setHasNoGoalKeeperError(false)}
          message={constants.HASNOGOALKEEPER}
        ></ErrorAlert>
      }
    </div>
  );
}
